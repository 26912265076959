@import '../../../variables';

.downloadFile {
  font-size: smaller;
  color: $link;
  cursor: pointer;
}

.status {
  font-size: smaller;
}

.success {
  color: $success-main;
}
.error {
  color: $error-light;
}
.pending {
  color: $info-dark;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translateY(-10px);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

.form-container {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.buttons-container {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

.boe .error404 path {
  fill: #64a8c8;
}

.boi .error404 path {
  fill: #253053;
}

.error404 {
  position: relative;
  width: 400px;
  margin: auto;
  margin-top: 70px;
  transform: rotate(-45deg);
  animation: wiggle 2s linear forwards;

  svg {
    width: 100%;
    height: auto;
  }

  #counter {
    height: 40px;
    overflow: hidden;
    width: 220px;
    position: absolute;
    top: 110px;
    left: 135px;
  }

  .digits {
    float: left;
    list-style-type: none;
    line-height: 1em;
    padding-left: 15px;
    font-size: 40px;
    font-weight: bold;
  }

  .digits-first {
    margin-top: -3em;
    /* number 4! */
  }

  .digits-second {
    margin-top: -8em;
    /* number 0! */
  }

  .digits-third {
    margin-top: -7em;
    /* number 4! */
    color: red;
  }

  .digits {
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }

  .luckie-1 {
    animation-name: luckie1;
  }

  .luckie-2 {
    animation-name: luckie2;
  }

  /* Animations */
  @keyframes luckie1 {
    100% {
      margin-top: -4em;
    }
  }

  @keyframes luckie2 {
    100% {
      margin-top: 0em;
    }
  }

  @keyframes wiggle {
    0%,
    7% {
      transform: rotate(0);
    }

    15% {
      transform: rotate(-15deg);
    }

    20% {
      transform: rotate(10deg);
    }

    25% {
      transform: rotate(-10deg);
    }

    30% {
      transform: rotate(6deg);
    }

    35% {
      transform: rotate(-4deg);
    }

    40%,
    100% {
      transform: rotate(0);
    }
  }
}

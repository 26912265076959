@import '../../../variables';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.page-paper {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.spinner {
  --highlight-color: #{$sidebar-secondary-base};

  &.base {
    --highlight-color: #{$sidebar-secondary-base};
  }
  &.custom-blue {
    --highlight-color: #{$sidebar-custom-blue};
  }
  &.birdz-green {
    --highlight-color: #{$birdz-green};
  }
  &.birdz-orange {
    --highlight-color: #{$birdz-orange};
  }
  &.birdz-blue {
    --highlight-color: #{$birdz-blue};
  }
  color: var(--highlight-color) !important;
}

@import '../../../variables';

.file-container {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;

  .file-wrapper {
    .icon {
      height: 40px;
    }

    .file-name {
      font-weight: 300;
    }

    .file-error {
      color: red;
      font-size: smaller;
    }
  }
}

.confirm-button-icon {
  min-width: 24px;
  max-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  min-height: 120px;
  // border: 1px solid red;
}

.dnd {
  width: 300px;
  height: 120px;
  border: 2px dashed $primary;
  border-radius: 4px;
  color: $primary;
  background-color: lighten($primary, 67);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: grab;

  &.drag-over {
    cursor: grabbing;
    background-color: lighten($primary, 60);
  }
}

.copy-paste-button {
  width: 300px;
}

.select-file-button {
  background-color: white !important;

  &:hover {
    background-color: lighten($primary, 65) !important;
  }
}

p {
  color: lighten($primary, 20);
  font-size: smaller;
}

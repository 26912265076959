@import '../../variables';

// $boeColor: #7ED17C;
// $boeColorBase: #95cde8;
// $boeColor: darken($boeColorBase, 10);

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.sidebar-collapsed {
  .sidebar {
    transition: width 0.3s ease;
    --sidebar-secondary: $sidebar-secondary-base;

    &.base {
      --sidebar-secondary: #{$sidebar-secondary-base};
    }
    &.custom-blue {
      --sidebar-secondary: #{$sidebar-custom-blue};
    }
    &.birdz-green {
      --sidebar-secondary: #{$birdz-green};
    }
    &.birdz-orange {
      --sidebar-secondary: #{$birdz-orange};
    }
    &.birdz-blue {
      --sidebar-secondary: #{$birdz-blue};
    }

    width: 52px;

    .sidebar-wrapper,
    .sidebar-wrapper .sidebar-scroll {
      overflow: initial;
    }

    .logo-rotate {
      img {
        width: 25px;
        padding: 5px;

        &:hover {
          cursor: pointer;
          animation: rotation 3s infinite cubic-bezier(0.66, 0, 0.43, 1.06);
        }
      }
    }

    .version {
      display: none;
    }

    .collapsed-item {
      position: relative;

      button {
        color: hsla(0, 0%, 100%, 0.65);
      }

      &.active {
        button {
          background-color: var(--sidebar-secondary);
          color: white;
        }
      }

      &:hover,
      &:has(.collapsed-item-content:hover) {
        button {
          background-color: $primary;
          color: white;
        }

        &.active {
          button {
            background-color: var(--sidebar-secondary);
          }
        }

        .collapsed-item-content {
          display: block; // Affiche l'élément au hover
        }
      }

      .collapsed-item-content {
        position: absolute;
        background-color: $sidebar-primary;
        top: 0;
        left: 58px;
        width: 230px;
        border-radius: 12px;
        display: none; // Caché par défaut
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 52px; // Aligné à l'icône
        width: 10px; // Largeur de la zone tampon
        height: 100%; // Prend toute la hauteur du parent
        background: transparent; // Invisible
        z-index: -1; // Ne gêne pas l'interaction
      }

      &:hover::after {
        background: rgba(0, 0, 0, 0); // Optionnel pour indiquer une zone étendue
      }

      .icon-button {
        &.dev {
          color: $birdz-blue;
        }
        &.ppd {
          color: $birdz-green;
        }
        &.prd {
          color: $birdz-orange;
        }
      }
    }

    .logout {
      right: auto;
      left: 8px;
    }
  }

  .main-panel {
    width: calc(100% - 52px);
  }
}

.sidebar {
  --sidebar-secondary: #{$sidebar-secondary-base};

  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 280px;
  max-width: 280px;
  left: 0;
  z-index: 1030;
  border-right: 1px solid #ddd;
  background-color: $sidebar-primary;
  color: hsla(0, 0%, 100%, 0.65);
  transition: width 0.3s ease;

  &.base {
    --sidebar-secondary: #{$sidebar-secondary-base};
  }
  &.custom-blue {
    --sidebar-secondary: #{$sidebar-custom-blue};
  }
  &.birdz-green {
    --sidebar-secondary: #{$birdz-green};
  }
  &.birdz-orange {
    --sidebar-secondary: #{$birdz-orange};
  }
  &.birdz-blue {
    --sidebar-secondary: #{$birdz-blue};
  }

  .environments {
    margin: 16px;
    margin-bottom: 0;
    padding: 8px 16px;
    color: white;
    border: 1px solid #434a60;
    border-radius: 12px;
    font-size: 0.875rem;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;

    .category {
      font-weight: 600;
      color: #8a94a6;
    }

    .env {
      &.dev {
        color: $birdz-blue;
      }
      &.ppd {
        color: $birdz-green;
      }
      &.prd {
        color: $birdz-orange;
      }
    }

    &.collapsed-item-content {
      margin: 0;
      padding: 16px;
      top: -7px !important;
    }
  }

  .logo {
    text-align: center;

    a {
      color: inherit;
      text-decoration: none;
    }

    .logo-icone {
      img {
        width: 25px;
        padding: 5px;

        &:hover {
          cursor: pointer;
          animation: rotation 3s infinite cubic-bezier(0.66, 0, 0.43, 1.06);
        }
      }
    }
    .logo-texte {
      width: 75px;
    }

    div.workspace {
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.3);
      padding: 5px;
      color: white;

      .title {
        font-weight: 600;
      }
    }
  }

  .MuiAccordion-root {
    background: $sidebar-primary;
    color: #b3b9c6;
    font-size: 0.875rem;
    font-weight: 500;
    // background: $primary;
    // color: hsla(0, 0%, 100%, 0.65);
    // border-top: 1px solid darken($primary, 5);
    // border-bottom: 1px solid darken($primary, 5);
    // border-radius: 0 !important;
    box-shadow: none;

    svg {
      font-size: 140%;
      margin-right: 20px;
      margin-left: 6px;
      margin-top: 1px;

      &.arrowIcon {
        margin: 0;
      }
    }

    .MuiCollapse-root {
      border: 1px solid var(--sidebar-secondary);
      border-top: none;
      border-radius: 0 0 12px 12px;
    }

    &.no-border {
      .MuiCollapse-root,
      .MuiAccordionSummary-root.active.expanded {
        border: none;
      }
    }

    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }

    &::before {
      display: none;
    }

    a {
      text-decoration: none;
    }

    a,
    svg {
      color: hsla(0, 0%, 100%, 0.65);
      display: flex;
      align-items: center;
    }

    div.Mui-expanded.MuiAccordionSummary-expandIconWrapper {
      transform: rotate(90deg);
    }

    .MuiAccordionSummary-root:hover,
    .MuiAccordionSummary-root.active {
      color: white;

      a,
      svg {
        color: white;
      }
    }

    .MuiAccordionSummary-root {
      // background-color: transparent;
      padding: 0 8px;
      border-radius: 12px;
      transition: background-color 0.2s ease-in-out;
      border: 1px solid transparent;

      .MuiAccordionSummary-content {
        .collapsed {
          position: relative;

          svg {
            display: none;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -6px;
            left: 0;
            height: 1px;
            width: calc(100% + 19px);
            background-color: #434a60;
          }
        }
      }

      &.level-1 {
        padding: 0 4px;
        border-radius: 6px;
      }

      &.active {
        background-color: var(--sidebar-secondary);
        border: 1px solid transparent;

        &.expanded {
          background-color: transparent;
          border: 1px solid var(--sidebar-secondary);
          border-bottom: 1px solid transparent;
          border-radius: 12px 12px 0 0;

          &.level-1 {
            border-radius: 6px 6px 0 0;
            border: 1px solid transparent;
            position: relative;
            // border-bottom: 1px solid #434a60;

            &::after {
              content: '';
              position: absolute;
              bottom: -2px;
              left: 0;
              height: 1px;
              width: 100%;
              // background-color: #434a60;
              background: linear-gradient(to right, transparent 0 8px, #434a60 8px 100%);
            }
          }
        }
      }
    }
  }

  .MuiAccordionDetails-root {
    position: relative;
    &.level-1 {
      // padding-bottom: 0;
      padding-right: 0;
    }

    .MuiBox-root {
      display: flex;
      align-items: center;
    }

    a:hover,
    .active a {
      color: white;
      font-weight: 600;
    }
  }

  &.boe {
    .sidebar-wrapper {
      border-top: 1px solid white;
    }
    .environments {
      border: 1px solid white;
      color: white !important;
      opacity: 0;
      animation: fadeIn 0.5s ease-out forwards;

      .category {
        color: white;
      }

      .env,
      .collapsed-icon {
        &.dev {
          color: darken($birdz-blue, 40);
        }
        &.ppd {
          color: $birdz-light-green;
        }
        &.prd {
          color: $birdz-light-orange;
        }
      }

      &.collapsed-item-content {
        margin: 0;
        padding: 16px;
        top: -7px !important;
      }
    }

    .collapsed-item {
      button {
        &:hover {
          background-color: lighten($boeColor, 10);
        }
      }
      &.active {
        button {
          background-color: darken($boeColor, 25);
        }
      }
      &:hover,
      &:has(.collapsed-item-content:hover) {
        // Empêche la perte du hover
        button {
          background-color: lighten($boeColor, 10);
        }

        &.active {
          button {
            background-color: darken($boeColor, 15);
          }
        }

        .collapsed-item-content {
          background-color: $boeColor;
          display: block; // Affiche l'élément au hover
        }
      }
      .icon-button {
        &.dev {
          color: darken($birdz-blue, 40);
        }
        &.ppd {
          color: $birdz-light-green;
        }
        &.prd {
          color: $birdz-light-orange;
        }
      }
    }

    .sidebar-item-child {
      color: white !important;
      svg {
        color: white;
      }
      &.level-1 {
        &:hover {
          background-color: hsla(0, 0%, 100%, 0.15);
        }
        &.activeItem {
          background-color: darken($boeColor, 15) !important;
        }
      }
    }

    background: $boeColor;

    button {
      background: $boeColor;

      &:hover {
        background: lighten($boeColor, 10);
      }
    }

    .MuiAccordion-root {
      background: $boeColor;
      border-left: 0px;
      border-right: 0px;

      // &:hover {
      //   background-color: darken($boeColor, 5);
      // }

      .MuiCollapse-root {
        border: 1px solid white;
        border-top: none;
        border-radius: 0 0 12px 12px;
      }

      &.no-border {
        .MuiCollapse-root,
        .MuiAccordionSummary-root.active.expanded {
          border: none;
        }
      }

      .MuiAccordionSummary-root {
        padding: 0 8px;
        border-radius: 12px;
        transition: background-color 0.2s ease-in-out;
        border: 1px solid transparent;

        .MuiAccordionSummary-content {
          .collapsed {
            position: relative;

            svg {
              display: none;
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -6px;
              left: 0;
              height: 1px;
              width: calc(100% + 19px);
              background-color: white;
            }
          }
        }

        &.level-1 {
          padding: 0 4px;
          border-radius: 6px;
        }

        &.active {
          background-color: darken($boeColor, 15);
          border: 1px solid transparent;

          &.expanded {
            background-color: transparent;
            border: 1px solid white;
            border-bottom: 1px solid transparent;
            border-radius: 12px 12px 0 0;

            &.level-1 {
              border-radius: 6px 6px 0 0;
              border: 1px solid transparent;
              position: relative;
              // border-bottom: 1px solid #434a60;

              &::after {
                content: '';
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 1px;
                width: 100%;
                // background-color: #434a60;
                background: linear-gradient(to right, transparent 0 8px, white 8px 100%);
              }
            }
          }
        }
      }
    }
  }

  button {
    // background: $primary;
    color: white;
    border-radius: 4px;
    &:hover {
      background: lighten($sidebar-primary, 10);
    }
  }

  display: flex;
  flex-direction: column;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 170px);
    margin-top: 15px;
    overflow: auto;
    width: 100%;
    z-index: 4;
    border-top: 1px solid #434a60;

    /* width */
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #4a5472;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #f8f9fa;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffffa6;
    }

    .sidebar-scroll {
      height: 100%;
      overflow: hidden visible;
      scrollbar-width: thin;
    }
  }

  .logout {
    position: absolute;
    bottom: 16px;
    left: 8px;
    z-index: 5;

    .btn {
      background: #fff;
      color: $primary;
    }
  }

  .logo {
    position: relative;
    // padding: 7px 0.7rem;
    z-index: 4;
  }

  .menu {
    .btn {
      text-align: left;
    }

    a {
      display: block;
      margin-top: 5px;
      margin-bottom: 5px;
      color: hsla(0, 0%, 100%, 0.65);
      text-decoration: none;
    }

    button {
      width: 100%;
    }

    .icon {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .version {
    color: #888;
    font-size: 12px;
    position: absolute;
    bottom: 24px;
    right: 0px;
  }
}

.sidebar-item-child {
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  // color: hsla(0, 0%, 100%, 0.65);

  &.MuiButtonBase-root {
    min-height: 26px;
    max-height: 26px;
  }

  &:hover {
    color: white;
    svg {
      color: white;
    }
  }

  &.level-1 {
    padding: 5px;
    border-radius: 6px;
    width: 100%;

    svg {
      font-size: 100%;
    }

    &.activeItem {
      background-color: var(--sidebar-secondary);
      width: fit-content;
      color: white;
      svg {
        color: white;
      }
    }
  }
}

/* bg:e4e4e4 , border: #dbdbdb -> light */
/* bg:1e1f26 , border: #2d2e36 -> dark */

.sugesstion-auto {
  display: block;
  border-radius: 7px;
  position: relative;
  transition: background 0.3s ease;
}

.form-control-auto input {
  border: 0;
  border-radius: 5px;
  width: 100%;
  padding: 15px 20px;
  outline: none;
}

.form-control-auto label {
  font-size: 10px;
  text-transform: uppercase;
  color: #949494;
  padding: 10px 0px 10px 20px;
}

.suggestions {
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 999;
  cursor: pointer;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.suggestions > div {
  padding: 15px 25px;
  border-bottom: 2px solid white;
  transition: all 0.1s ease;
}

.suggestions > div:hover {
  background-color: gray !important;
  color: #000000;
}

.suggestions > div,
.form-control-auto input {
  font-size: 14px !important;
  background-color: lightgray;
  color: black;
}

.calendar {
  .eventContent {
    background: #95cde8;
    border-radius: 3px;
    margin: 2px;
    padding: 3px;
  }

  .technician {
    text-align: center;

    .technicianChip {
      background-color: #eee;
      border-radius: 20px;
      padding: 1px 10px 2px 5px;
      display: inline-block;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 5px;

      svg {
        margin-top: -2px;
        font-size: 15px;
      }
    }
  }

  .fc-timeline-header-row .fc-timeline-slot {
    border: 1px solid #ccc !important;
    border-top: 0 !important;
    border-bottom: 0 !important;

    &[data-date$='08:00:00'] {
      border-left: 1px solid #bbb !important;
    }

    div.fc-timeline-slot-frame {
      display: flex;
      justify-content: center;
    }

    a {
      text-decoration: none;
    }
  }

  .fc-timeline-slots tr td[data-date$='08:00:00'] {
    border-left: 1px solid #ddd !important;
  }

  .fc-timeline-slots tr td {
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .fc-datagrid-header {
    .fc-datagrid-cell div.fc-datagrid-cell-frame {
      display: flex;
      justify-content: center;
    }
  }

  .fc-license-message {
    display: none;
  }

  table.fc-scrollgrid {
    border-radius: 5px;

    th {
      background: rgb(222, 226, 230);
    }

    & > tbody > tr > td:first-child {
      background: rgb(249, 249, 249);
    }
  }

  .fc .fc-resource-timeline-divider {
    width: 0px !important;
  }

  .fc-resourceTimelineMonth-view {
    .fc-timeline-slots tr td {
      border-left: 1px solid #ddd !important;
      border-right: 1px solid #ddd !important;
    }
  }
}

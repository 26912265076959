.dragged-row {
  opacity: 0.2;
  background-color: rgb(181, 179, 179);
  transform: scale(1.01);
  transition:
    transform 0.2s ease,
    opacity 0.2s ease;
}

.table-row {
  background-color: white;

  transition:
    filter 0.4s ease,
    opacity 0.4s ease;

  &.not-dragging {
    /* filter: blur(2px); */
    opacity: 0.5;
    transition:
      filter 0.4s ease,
      opacity 0.4s ease;
  }
}

.header-row {
  background-color: #dee2e6;
}

@import 'variables';
@import 'leaflet/dist/leaflet.css';

.leaflet-container {
  width: 100wh;
  height: 100vh;
}

/** Extra CSS from Bidir, styles will be move in Birdz-React-Library **/
$sidebar-width: calc(100% - 260px) !default;
$sidebar-mini-width: calc(100% - 80px) !default;

.page-link.active,
.active > .page-link {
  background-color: $primary;
}

body {
  background-image: url('./bg-light.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 15px;
}

h3 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 350;
  margin-top: 0;
}

h4 {
  font-size: 20px;
  font-weight: 300;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  overflow: hidden;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $sidebar-width;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 0.3s ease;

  .navbar {
    .avatar {
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      margin-right: 10px;
      margin-top: -10px;
      margin-bottom: -10px;
    }
  }
}

.page-content {
  padding: 10px;
}

.pagination {
  justify-content: flex-end;
}

.toaster {
  position: absolute;
  top: 20px;
  right: 20px;

  &.success {
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
}

.block {
  background: #fcfcfc;
  padding: 10px;
  border: 1px solid #dee2e6;
  margin-bottom: 15px;
  border-radius: 5px;

  h2 {
    margin-bottom: 25px;
  }
}

.fileInput {
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.link {
  cursor: pointer;
}

.link-underline {
  text-decoration: underline;
}

.actions a,
td a {
  color: #373a3c;
}

.lastLogin {
  display: block;
  position: absolute;
  right: 12px;
  margin-top: 11px;
  font-style: italic;
  font-size: 13px;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: white;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.btns {
  input[type='button'],
  input[type='submit'],
  button {
    padding: 6px 50px;
  }

  input[type='submit'],
  .btn-primary {
    margin-left: 10px;
  }
}

.modal-80w {
  max-width: 80%;
}

.clickable {
  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
}

.orderable {
  cursor: pointer;
}

.ordering {
  padding: 3px;
  margin-left: 15px;
  display: inline-block;
}

.orderPosition {
  width: 16px;
  height: 16px;
  font-size: 10px;
}

.pageSize {
  display: inline-block;

  > div {
    z-index: 100;
    display: inline-block;
    width: 80px;
  }
}

.text-center {
  text-align: center !important;
}

.MuiAccordionSummary-content h3 {
  margin-bottom: 0;
}

.highlight {
  color: lighten($primary, 20);
}

.d-flex {
  display: flex !important;

  &.flex-grow-1 {
    flex-grow: 1 !important;
  }

  &.gap-50 {
    gap: 0.5rem !important;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.justify-content-center {
    justify-content: center !important;
  }

  &.justify-content-end {
    justify-content: end !important;
  }

  &.justify-content-between {
    justify-content: space-between !important;
  }

  &.align-items-center {
    align-items: center !important;
  }

  &.align-items-start {
    align-items: start !important;
  }

  &.align-items-end {
    align-items: end !important;
  }
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mr-1 {
  margin-right: 1rem !important;
}
.mr-50 {
  margin-right: 0.5rem !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-50 {
  margin-left: 0.5rem !important;
}
.ml-25 {
  margin-left: 0.25rem !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-50 {
  margin-top: 0.5rem !important;
}
.mt-25 {
  margin-top: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}

.p-0 {
  padding: 0 !important;
}
.p-50 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 1rem !important;
}
.pr-1 {
  padding-right: 1rem !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pt-1 {
  padding-top: 1rem !important;
}
.pb-1 {
  padding-bottom: 1rem !important;
}

.highlightedText {
  &:hover {
    color: $link;
  }
}

.border-test {
  border: 1px solid red !important;

  &.blue {
    border: 1px solid blue !important;
  }
  &.green {
    border: 1px solid green !important;
  }
}

.input-created_at_after,
.input-created_at_before,
.input-date_after,
.input-date_before,
.input-started_at_after,
.input-started_at_before {
  width: 100% !important;
  input {
    padding: 8.5px 14px;
  }
}

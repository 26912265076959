$primary: #3a4255;

$birdz-green: #7ed17c; //#635bff
$birdz-light-green: #e0f4d3;
$birdz-orange: #f7a677;
$birdz-light-orange: #ffe6dc;
$birdz-blue: #95cde8;
$birdz-light-blue: #e7eee8;

$sidebar-primary: #1a1f2c; //#121621
$sidebar-secondary-base: #434a60;
$sidebar-custom-blue: #635bff;

$link: #3a64c5;

$error-main: #f44336;
$error-dark: #d32f2f;
$error-light: #e57373;

$success-main: #66bb6a;
$success-dark: #388e3c;
$success-light: #81c784;

$info-main: #29b6f6;
$info-dark: #0288d1;
$info-light: #4fc3f7;

$boeColorBase: #95cde8;
$boeColor: darken($boeColorBase, 10);

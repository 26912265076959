.switch {
  border: 3px solid red;

  &.MuiFormGroup-root
    .css-dmmspl-MuiFormGroup-root
    .MuiFormControlLabel-root
    .MuiFormControlLabel-labelPlacementEnd
    .css-j204z7-MuiFormControlLabel-root
    .MuiSwitch-root
    .MuiSwitch-sizeMedium
    .css-julti5-MuiSwitch-root
    .MuiSwitch-track
    .css-1yjjitx-MuiSwitch-track {
    border: 3px solid blue !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    // transform: translateY(-10px);
  }
  to {
    opacity: 1;
    // transform: translateY(0);
  }
}

.table-container {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;

  .transitioned {
    opacity: 0;
    animation: fadeIn 0.3s ease-in forwards;
  }
}
